import IconBurgerMenu from "./assets/icon/burger-menu.svg";
//
import { createMemo, splitProps, children, onMount, Switch, Match, Show, createEffect } from "solid-js";
import type { MainProps } from "./_model";
import { SideNav } from ":shared/components/route";
import { global } from ":global";
import "./style/nav-side.css";
import { solidstate } from ":shared/utils/state-manager";

export default function DashboardLayout(props: MainProps) {
  const $breakpoint = global.store.hooks.dom.useBreakpoints();
  const $show_sidenav = solidstate.create(false);
  global.store.triggers.route.onNavigated(() => {
    if ($show_sidenav.value) {
      $show_sidenav.set(false);
    }
  });
  // createEffect(() => {
  //   console.log("asd :: ", $show_sidenav.value);
  // });
  return (
    <main class={`flex flex-col w-full h-full space-y-2`}>
      <Switch fallback="awaiting breakpoint layout...">
        {/* phone view */}
        <Match when={$breakpoint()?.misc.md.widthIsLess}>
          <section class="relative flex flex-col !z-1 w-full h-full">
            {props.childrenTop}
            <div
              class="relative flex flex-row w-full h-2.5rem bg#p  px-4 items-center justify-end"
              // onclick={() => $show_sidenav.set(true)}
            >
              <div
                class={`
                absolute left-5 flex w-fit h-fit
                transition transition-all duration-300 ease-in-out !z-100
                ${$show_sidenav.value ? "!left-85% " : ""}
                `}
                onclick={() => $show_sidenav.set((s) => !s)}
              >
                <Show when={$show_sidenav.value} fallback={<IconBurgerMenu class="w-1rem h-1rem" />}>
                  <i class="i-lets-icons:close-ring-duotone w-40px h-50px  text#a" />
                </Show>
              </div>

              <img
                src="/white_logo.svg"
                alt="white_logo"
                class={`w-4rem h-full !z-99 
              ${$show_sidenav.value ? "hidden" : ""}
              `}
              />
            </div>
            <div
              class={`
              absolute top-0 bg-black/30 min-w-full w-full min-h-full !z-99
              ${$show_sidenav.value ? "" : "hidden"}
              `}
            />
            <div
              class={`
              absolute top-0 -left-100% flex flex-col !min-w-80vw !max-w-80vw h-full bg#p  pt-4 px-2.5 !z-100 !overflow-hidden
              transition transition-all duration-300 ease-in-out space-y-2 
              ${$show_sidenav.value ? "!left-0" : ""}
              `}
            >
              <img src="/white_logo.svg" alt="white_logo" />
              <SideNav
                {...props.sideNav}
                triggers={{
                  ...props.sideNav?.triggers,
                  onClickedOutside: () => {
                    if ($show_sidenav.value) {
                      $show_sidenav.set(false);
                    }
                    props.sideNav?.triggers?.onClickedOutside?.();
                  },
                }}
                class={`nav-side ${props.sideNav.class ?? ""}`}
              />
            </div>
            <div class="w-full max-w-full min-w-full h-full  !overflow-hidden  bg#pt text#p !z-0">{props.children}</div>
          </section>
        </Match>
        {/* desktop view */}
        <Match when={$breakpoint()?.misc.md.widthIsGreaterOrEquals}>
          <section class="flex flex-row w-full h-full !overflow-hidden">
            <div class="flex flex-col w-15rem min-w-12.5rem min-h-full bg#p  pt-4 px-2.5 scrollbar">
              <img src="/white_logo.svg" alt="white_logo" class=" mb-5" />
              <SideNav {...props.sideNav} class={`nav-side ${props.sideNav.class ?? ""}`} />
            </div>
            <div class="flex flex-col w-full h-full !overflow-hidden">
              {props.childrenTop}
              <div class="w-97% max-w-97% min-w-97% h-full ml-3 my-4  rounded-2xl !overflow-hidden bg#pt text#p">
                {props.children}
              </div>
            </div>
          </section>
        </Match>
      </Switch>

      {/* <footer class="flex flex-row w-full gap-x-3 h-50px min-h-50px max-h-50px text-0.7rem items-center justify-center">
        <span>©Museums Future 2023</span>
        <span>|</span>
        <Cmp.I18n.LanguageSelector />
      </footer> */}
    </main>
  );
}
